@tailwind base;
@tailwind components;
@tailwind utilities;

@import "flowbite";

:root {
  --foreground-rgb: 0, 0, 0;
  /* --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255; */
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

@layer utilities {
  .text-header1 {
    color: var(--Black, #000);
    font-family: "p22-mackinac-pro";
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -2.4px;
  }

  .text-header2 {
    color: var(--Black, #000);
    font-family: "p22-mackinac-pro";
    font-size: 46px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1.38px;
  }

  .text-header3 {
    color: var(--Black, #000);
    font-family: "p22-mackinac-pro";
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1.08px;
  }
}

button.rdp-button_reset.rdp-button.h-9.w-9.p-0.font-normal.rounded-full.text-black.bg-variable-collection-primary-100.rounded-none.text-black.text-gray-500.opacity-50 {
  color: rgb(255 213 200 / var(--tw-bg-opacity));
  opacity: inherit;
  --tw-bg-opacity: 1;
  background-color: rgb(255 213 200 / var(--tw-bg-opacity));
}

button.rdp-button_reset.rdp-button.h-9.w-9.p-0.font-normal.day_outside.text-gray-500.opacity-50 {
  opacity: 0;
}

td:has(.day-range-end) {
  background-color: rgb(255 213 200 / var(--tw-bg-opacity));
    bottom: -1px;
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
    left: -25px;
}

td:has(.day-range-end.day_range_start){
  background-color: unset;
  bottom: unset;
  right: unset;
}

body td .day-range-end.day_range_start{
  position: absolute;
  bottom: unset;
  left: 12px;
}

td:has(.day_range_start) {
  background-color: rgb(255 213 200 / var(--tw-bg-opacity));
    bottom: -1px;
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
    right: -24px;
}

body .day-range-end{
  position: absolute;
    bottom: 0px;
    left: 50px;
}

body .day_range_start{
  position: absolute;
    right: 50px;
    bottom: 0px;
}

body .onboarding-step5 .swiper-wrapper{
  padding-left: 1.25rem;
  scroll-padding-left: 1.25rem;
}

body .itinerary-list .swiper-wrapper{
  padding-left: 1.25rem;
  scroll-padding-left: 1.25rem;
}

body .itinerary-regions .swiper-wrapper{
  padding-left: 1.25rem;
  scroll-padding-left: 1.25rem;
}

body .guides-img-item{
  left: 16px;
  width: 92%;
}

/* Medium Device = 1200px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  body .guides-img-item{
    width: 93%;
  }
}

/* Tablet Device = 768px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  body .guides-img-item{
    width: 91%;
  }
}


@media only screen and (max-width: 767px) {}

/* Wide Mobile = 480px */
@media only screen and (max-width: 479px) {
  td:has(.day_range_start) {
    right: -7px;
  }
  
  td:has(.day-range-end) {
    left: -7px;
  }

  body .day-range-end{
      left: 15px;
  }
  
  body .day_range_start{
    right: 14px;
  }

  body .guides-img-item{
    left: 13.5px;
  }

}

/* Small Device = 320px */
@media only screen and (max-width: 320px){
  body .guides-img-item{
    left: 10px;
  }
}